import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Knowledge Centre" />

    <header className="header header--basic ">
        <div className="header__content">
            <div className="container">
                <div className="row">
                    <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                        <h1 className="header__title">Digital First Sales Training</h1>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div className="landing landing--base outsource-sales-content">
        <div className="landing__item">
            <div className="container">
                <div  className="landing__columns row">
                    <div  className="col-xs-12 col-sm-6">
                            <img src="../media/images/landing-552X580_digital-first-sales-banner.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container outsource-sales-content-row" >
                <div className="row">
                    <div className="rich-text col-xs-12 col-sm-6 col-lg-11">
                        <h2 className="landing_column_header">Modern Selling Program(s) – Digital-First & Data-Led.​</h2>
                        <p>
                            Today’s educated B2B buyers begin the buying journey online, meaning that salespeople have to shift their focus to digital-first and data-led sales. The SalesTribe approach has been developed to address changing buyer behaviour - when buyers change how they buy, then sellers must also change how they sell.​ </p>
                            <p>
                                Digital-First selling prioritizes digital channels and technology in the buying process. SalesTribe has created a range of world-leading programs designed to teach salespeople how to use digital tools and platforms to reach, engage, and convert customers, rather than relying solely on traditional sales methods like phone calls, in-person meetings, and printed materials.​
                            </p>
                            <p>Your business success now hingers on teaching your sales teams how to engage your buyers where they now reside - online. ​</p>
                            <br></br>
                    </div>
                </div>
                <div className="row">
                    <div  className="outsource-sales-content-image col-xs-12 col-sm-6 col-lg-11">
                    <img src="../media/svg/landing_256x580_digital_sales_training.svg" alt="" />
                    </div>
                </div>
        </div>
        <div className="container outsource-sales-content-row">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-lg-11">
                        <h2 className="landing_column_header">Modern Sales Leadership​​</h2>
                        <p>Buying has changed (dramatically), meaning that sales is now also changing. But what about Sales Leadership?​</p> 
                                <p>To become a successful leader in the global, digital, connected, economies, you must consistently display skills, traits, and qualities that are necessary to succeed in today's fast-paced and competitive sales environment. </p> 
                                <p>Learn how to think strategically and create a long-term vision for their sales team, taking into account changing market conditions, customer needs, and emerging trends. Given the abundance of data now available, a modern sales leader knows how to collect and analyze data to gain insights into buyer behavior, intent, sales performance, and market trends, and then use this information to make informed strategic decisions that create competitive advantage. </p> 
                                <p>Successful sales leaders know exactly how to create a buyer-obsessed sales culture, where the needs and preferences of the buyer are truly at the center of all sales activities.​</p> <br></br>
                    </div>
                </div>
                <div className="row">
                <div className="outsource-sales-content-image col-xs-12 col-sm-6 col-lg-11">
                        <img src="../media/svg/landing-552X580_digital_first_sales_modern_sales.svg" alt="" />
                    </div>
                </div>
        </div>
        <div className="container outsource-sales-content-row">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-lg-11">
                        <h2 className="landing_column_header">Digital-First Selling in One Day​</h2>
                        <p>Digital Sales Enablement is about utilising the right data, tools, platforms and content for sales performance improvement, but you have to marry tools with methodology to be successful. The tool should simply reinforce the way your buyers want to buy. ​</p> 
                                <p>This 1-Day Masterclass introduces participants to the critical concepts of Digital-First Selling. </p> 
                                <p>The Digital-First approach involves leveraging digital channels such as social media, content, buyer-intent data, websites, mobile, and online marketing techniques to create attention, generate leads and build relationships with prospects. Digital-First emphasizes the use of data analytics and automation to optimize the sales process and increase efficiency.​</p> 
                                <p>Most importantly, Digital-First selling teaches salespeople to stop ‘chasing’ and start ‘attracting’ buyers.​</p><br></br>
                    </div>
                </div>
                <div className="row">
                    <div className="outsource-sales-content-image col-xs-12 col-sm-6 col-lg-11">
                    <img src="../media/svg/landing_552X580_digital_first_sales_digital_first_selling.svg" alt="" />
                    </div>
                </div>
        </div>
        <div className="container outsource-sales-content-row">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-lg-11">
                        <h2 className="landing_column_header">Modern Sales Leadership​</h2>
                        <p>Are you organizing an event or workshop?</p> 
                            <p>Are you looking for someone who can inspire and educate your employees or colleagues?</p>
                            <p>Our modern selling specialists will provide you and your company with critical insights into current digital trends that can help you form new strategies and get ahead in the game.​</p>
                    </div>
                </div>
                <div className="row">
                    <div className="outsource-sales-content-image col-xs-12 col-sm-6 col-lg-11">
                        <img src="../media/svg/landing-552X580_digital_first_sales_kick_off_events.svg" alt="" />
                    </div>
                </div>
        </div>
    </div>
  </Layout>
)

export default IndexPage
